export const COLORS = {
	ceo: '#0f4784',
	trader: '#e5e1de',
	priceAction: '#8c5e61',
	marketProfile: '#72a4bc',
	crypto: '#9fa82a',
	operations: '#399bbb',
	xau: '#afb69e',
	indices: '#9e9b9e',
	forex: '#a9bfc4',
	news: '#f16b5f',
};
