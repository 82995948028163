import React from 'react';
import styled from 'styled-components';

export const IndividualModalContent = () => {
	return (
		<Wrap>
			<h1>Індивідуальні консультації з менторами</h1>
			<p>
				У GS Club ми націлені на ваш персональний розвиток. Кожен учасник має
				можливість двічі на місяць отримати індивідуальну консультацію від
				ментора тривалістю 15-20 хвилин. Наші досвідчені члени команди готові
				поділитися знаннями та стратегіями, які допоможуть вам досягти нових
				висот у трейдингу та допомогти виправити помилки. Незалежно від рівня
				вашого досвіду — ми сприятимемо вашому успіху.
			</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
