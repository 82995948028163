import React, { FC } from 'react';
import styled from 'styled-components';
import { relocate } from '../Header';
const arrowBlack = require('../../assets/arrowBlack.png');
const arrowWhite = require('../../assets/arrowWhite.png');

interface PlanProps {
	term: string;
	price: string;
	info: string;
	bgColor: string;
	picture: any;
	isBlack: boolean;
	link: string;
}

export const Plan: FC<PlanProps> = ({
	term,
	price,
	info,
	bgColor,
	picture,
	isBlack,
	link,
}) => {
	return (
		<Wrap bgColor={bgColor} isBlack={isBlack} onClick={() => relocate(link)}>
			<div>
				<p>{term}</p>
				<h2>{price}</h2>
				<div>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a>{info}</a>
					<div>
						<img src={isBlack ? arrowBlack : arrowWhite} alt="arrow" />
					</div>
				</div>
			</div>
			<div>
				<img src={picture} alt="oneMonth" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div<{ isBlack: boolean; bgColor: string }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-radius: 23px;
	padding: 35px 17px 65px;
	text-align: center;
	min-height: 380px;
	background: ${({ bgColor }) => bgColor};
	color: ${({ isBlack }) => (isBlack ? 'black' : 'white')};
	margin: 12px;
	width: 100%;
	cursor: pointer;

	@media screen and (max-width: 734px) {
		width: 320px;
	}

	&:hover a {
		text-decoration: underline;
	}

	p {
		font-size: 21px;
		font-family: 'SF-Regular';
	}

	h2 {
		font-size: 55px;
		margin-top: 14px;
	}

	a {
		font-size: 14px;
	}

	> div {
		&:nth-of-type(2) {
			height: 110px;
			img {
				height: 100%;
			}
		}

		> div {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 12px;

			> div {
				width: 5px;
				margin-left: 5px;

				img {
					width: 100%;
				}
			}
		}
	}
`;
