export const buildMsg = (
	eurusd: boolean,
	usdcad: boolean,
	usdjpy: boolean,
	gbpusd: boolean,
	usdchf: boolean,
	audusd: boolean,
	xauusd: boolean,
	xagusd: boolean,
	oilusd: boolean,
	spx500: boolean,
	dj30: boolean,
	nas100: boolean,
	ger40: boolean
) => {
	let msg = 'Research:';

	if (eurusd) msg += ' EURUSD';
	if (usdcad) msg += ' USDCAD';
	if (usdjpy) msg += ' USDJPY';
	if (gbpusd) msg += ' GBPUSD';
	if (usdchf) msg += ' USDCHF';
	if (audusd) msg += ' AUDUSD';
	if (xauusd) msg += ' XAUUSD';
	if (xagusd) msg += ' XAGUSD';
	if (oilusd) msg += ' OILUSD';
	if (spx500) msg += ' SPX500';
	if (dj30) msg += ' DJ30';
	if (nas100) msg += ' NAS100';
	if (ger40) msg += ' GER40';

	return msg;
};
