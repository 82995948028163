import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusBlack.png');

export const LiveMobile = () => {
	return (
		<Wrapper>
			<h1>
				FX, Gold <br /> and Indices <br />
				Live-Trading <br />
				with GoShawty
				<br /> and the Team.
			</h1>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	border-radius: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 40px;
	height: 530px;

	div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			cursor: pointer;
			width: 100%;
			opacity: 0.4;

			&:hover {
				opacity: 0.25;
			}
		}
	}

	h1 {
		line-height: 1.1;
		text-align: left;
		font-size: 50px;
		background: #939393;
		background: linear-gradient(to bottom right, #939393 0%, #c8c8c8 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: ${fadeInUp} 3s forwards;
	}
`;
