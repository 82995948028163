import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const TagCEO = () => <Tag colorCode={COLORS.ceo}>CEO</Tag>;
export const TagCOO = () => <Tag colorCode={COLORS.ceo}>COO</Tag>;
export const TagCMO = () => <Tag colorCode={COLORS.ceo}>CMO</Tag>;
export const TagTrader = () => (
	<Tag colorCode={COLORS.trader} isBlack>
		Trader
	</Tag>
);
export const TagPA = () => (
	<Tag colorCode={COLORS.priceAction}>Price Action</Tag>
);
export const TagMP = () => (
	<Tag colorCode={COLORS.marketProfile}>Market Profile</Tag>
);
export const TagCrypto = () => <Tag colorCode={COLORS.crypto}>Crypto</Tag>;

export const TagXAU = () => (
	<Tag colorCode={COLORS.xau} isBlack>
		XAU
	</Tag>
);
export const TagIndices = () => <Tag colorCode={COLORS.indices}>Indices</Tag>;
export const TagFX = () => (
	<Tag isBlack colorCode={COLORS.forex}>
		Forex
	</Tag>
);

export const TagNews = () => <Tag colorCode={COLORS.news}>News Research</Tag>;
export const TagSecret = () => <Tag colorCode="#000">Big Secret</Tag>;
export const TagCryptoActivities = () => (
	<Tag colorCode={COLORS.crypto}>Crypto Activites</Tag>
);

const Tag = styled.div<{ colorCode?: string; isBlack?: boolean }>`
	font-family: 'SF-Bold';
	padding: 4px 10px;
	border-radius: 7px;
	background: ${({ colorCode }) => colorCode || '#e07f7f'};
	color: ${({ isBlack }) => (isBlack ? '#000' : '#fff')};
	height: fit-content;
	width: fit-content;
	margin-top: 7px;

	&:nth-of-type(1) {
		margin-top: 0;
	}

	@media screen and (max-width: 1170px) {
		font-size: 12px;
	}
`;
