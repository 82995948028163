import React, { RefObject, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Bars } from '../Bars';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');

export const MorningMobile = () => {
	const ref = useRef();
	const { inViewport } = useInViewport(
		ref as unknown as RefObject<HTMLElement>
	);

	return (
		// @ts-ignore
		<Wrapper ref={ref}>
			<h1>Morning calls and week overviews.</h1>
			<Bars isSmall inViewport={inViewport} />
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background: #000;
	border-radius: 10px;
	padding-left: 30px;
	padding-top: 40px;
	height: 560px;

	> div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			cursor: pointer;
			width: 100%;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	h1 {
		font-size: 45px;
		color: white;
		animation: ${fadeInUp} 4s forwards;
	}
`;
