import React, { FC } from 'react';

type TickProps = {
	color?: string;
};

export const Tick: FC<TickProps> = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="31"
			zoomAndPan="magnify"
			viewBox="0 0 23.25 23.249999"
			height="31"
			preserveAspectRatio="xMidYMid meet"
			version="1.0"
		>
			<defs>
				<clipPath id="9acef26f0c">
					<path
						d="M 0 0 L 22.523438 0 L 22.523438 22.523438 L 0 22.523438 Z M 0 0 "
						clip-rule="nonzero"
					/>
				</clipPath>
				<clipPath id="ec44e5c70e">
					<path
						d="M 14.632812 14.753906 L 18.265625 14.753906 L 18.265625 18.386719 L 14.632812 18.386719 Z M 14.632812 14.753906 "
						clip-rule="nonzero"
					/>
				</clipPath>
			</defs>
			<g clip-path="url(#9acef26f0c)">
				<path
					fill={color}
					d="M 0 11.261719 C 0 5.058594 5.058594 0 11.257812 0 C 17.460938 0 22.519531 5.058594 22.519531 11.261719 C 22.519531 17.460938 17.460938 22.519531 11.257812 22.519531 C 5.058594 22.519531 0 17.460938 0 11.261719 Z M 16.867188 7.3125 C 16.34375 6.832031 15.519531 6.859375 15.03125 7.382812 L 9.738281 13.058594 L 7.421875 10.902344 C 6.898438 10.414062 6.097656 10.441406 5.601562 10.945312 C 5.09375 11.464844 5.109375 12.304688 5.644531 12.808594 L 8.914062 15.855469 C 9.449219 16.355469 10.273438 16.308594 10.769531 15.777344 L 16.9375 9.160156 C 17.433594 8.632812 17.394531 7.796875 16.867188 7.3125 Z M 16.867188 7.3125 "
					fill-opacity="1"
					fill-rule="evenodd"
				/>
			</g>
			<g clip-path="url(#ec44e5c70e)">
				<path
					fill={color}
					d="M 14.632812 16.570312 C 14.632812 15.570312 15.445312 14.753906 16.449219 14.753906 C 17.449219 14.753906 18.265625 15.570312 18.265625 16.570312 C 18.265625 17.570312 17.449219 18.386719 16.449219 18.386719 C 15.445312 18.386719 14.632812 17.570312 14.632812 16.570312 Z M 17.351562 15.933594 C 17.269531 15.855469 17.132812 15.859375 17.054688 15.945312 L 16.203125 16.859375 L 15.828125 16.511719 C 15.746094 16.433594 15.613281 16.4375 15.535156 16.519531 C 15.453125 16.601562 15.457031 16.738281 15.542969 16.820312 L 16.070312 17.308594 C 16.15625 17.390625 16.289062 17.382812 16.367188 17.296875 L 17.363281 16.230469 C 17.441406 16.144531 17.4375 16.011719 17.351562 15.933594 Z M 17.351562 15.933594 "
					fill-opacity="1"
					fill-rule="evenodd"
				/>
			</g>
		</svg>
	);
};
