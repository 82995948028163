import React from 'react';
import styled from 'styled-components';
import { relocate } from '../Header';

export const Connect = () => {
	return (
		<Wrap>
			<h1>
				Не знайшли актив або метрику,
				<br />
				які вас цікавлять?
			</h1>
			<p>Напиши нам і ми зберемо дані спеціально для тебе.</p>
			<Button onClick={() => relocate('https://t.me/gsclub_manager')}>
				Звʼязок
			</Button>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding-top: 100px;
	padding-bottom: 100px;
	width: 100%;
	background: #e8e8ed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 60px;
		text-align: center;

		@media screen and (max-width: 600px) {
			font-size: 23px;
		}
	}

	p {
		margin-top: 25px;
		font-size: 21px;
		color: #86868b;
		text-align: center;
		max-width: 800px;

		@media screen and (max-width: 600px) {
			width: 100%;
			margin-top: 10px;
			font-size: 15px;
		}
	}
`;

const Button = styled.button`
	margin-top: 30px;
	font-family: 'SF-Regular';
	color: #fff;
	background: #1d1d1f;
	border: none;
	font-size: 17px;
	padding: 6px 21px;
	cursor: pointer;
	border-radius: 980px;
	letter-spacing: -0.022em;
	text-align: center;

	@media screen and (max-width: 600px) {
		margin-top: 15px;
	}

	&:hover {
		background: #2c2c2e;
	}
`;
