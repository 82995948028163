import React from 'react';
import styled from 'styled-components';

export const PropModalContent = () => {
	return (
		<Wrap>
			<h1>Можливість отримати проп-акаунт за активність</h1>
			<p>
				Ми цінуємо проактивність та відданість своїй справі. Найактивніші й
				найперспективніші учасники нашої спільноти мають змогу отримати
				проп-аккаунти та можливість торгувати з більшим капіталом, мінімізуючи
				власні ризики. Приєднуйтесь до GS Club спільноти, і ми підтримуватимемо
				вас на кожному кроці!
			</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
