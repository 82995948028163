import React from 'react';
import styled from 'styled-components';
import { Plan } from './Card';
const oneMonth = require('../../assets/1month.png');
const threeMonth = require('../../assets/3month.png');
const lifetime = require('../../assets/lifetime.png');
const pulse = require('../../assets/pulse.png');

export const Plans = () => {
	return (
		<Wrap id="plans">
			<h1>Обери свою підписку.</h1>
			<section>
				<Plan
					term="GS Club Lifetime Access"
					price="$749"
					info="Разова оплата"
					bgColor="#1e1e1f"
					picture={lifetime}
					isBlack={false}
					link="https://t.me/gsclub_manager?text=Hello,%20Lifetime!"
				/>
				<Plan
					term="GS Club - 3 Місяці"
					price="$159"
					info="$199 - перших 3 місяці"
					bgColor="#007aff"
					picture={threeMonth}
					isBlack={false}
					link="https://t.me/gsclub_manager?text=Hello,%203%20Months!"
				/>
				<Plan
					term="GS Club - 1 Місяць"
					price="$59"
					info="$79 - перший місяць"
					bgColor="#d9d6cc"
					picture={oneMonth}
					isBlack={true}
					link="https://t.me/gsclub_manager?text=Hello,%201%20Month!"
				/>
				<Plan
					term="GoShawty Pulse"
					price="$20"
					info="Доступ по аналітики GoShawty"
					bgColor="#469853"
					picture={pulse}
					isBlack={false}
					link="https://t.me/gsclub_manager?text=Hello,%20Pulse!"
				/>
			</section>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 79px 0 100px;
	background: #e8e8ed;

	h1 {
		font-size: 64px;
	}

	@media screen and (max-width: 734px) {
		h1 {
			font-size: 32px;
		}
	}

	section {
		margin-top: 55px;
		display: flex;
		justify-content: space-between;
		width: 1200px;

		@media screen and (max-width: 1210px) {
			width: 95%;
		}

		@media screen and (max-width: 1155px) {
			flex-wrap: wrap;
			justify-content: center;
			max-width: 900px;

			> div {
				width: 300px;
			}
		}

		@media screen and (max-width: 734px) {
			flex-direction: column;
			align-items: center;
			width: auto;
		}
	}
`;
