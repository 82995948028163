import React, { FC } from 'react';

type AWPProps = {
	color?: string;
};

export const AWP: FC<AWPProps> = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="120"
			zoomAndPan="magnify"
			viewBox="0 0 90 17.25"
			height="23"
			preserveAspectRatio="xMidYMid meet"
			version="1.0"
		>
			<defs>
				<clipPath id="dd18b5c3b7">
					<path
						d="M 1.875 0 L 88 0 L 88 15.8125 L 1.875 15.8125 Z M 1.875 0 "
						clip-rule="nonzero"
					/>
				</clipPath>
			</defs>
			<g clip-path="url(#dd18b5c3b7)">
				<path
					fill={color || 'black'}
					d="M 15.9375 14.9375 L 22.777344 15.875 L 23.632812 12.457031 L 28.078125 12.457031 L 28.933594 11.429688 L 32.867188 11.429688 L 38.167969 9.804688 L 54.160156 9.804688 L 54.160156 7.003906 L 87.9375 7.003906 L 87.9375 5.425781 L 36.546875 5.425781 L 36.546875 3.507812 L 36.6875 3.507812 L 40.089844 4.160156 L 40.089844 4.164062 L 44.652344 4.164062 L 44.652344 0.746094 L 40.089844 0.746094 L 36.6875 1.398438 L 36.6875 1.402344 L 34.351562 1.402344 L 34.351562 0 L 32.070312 0 L 32.070312 1.402344 L 21.238281 1.402344 L 21.238281 3.507812 L 28.679688 3.507812 L 28.679688 5.425781 L 24.773438 5.425781 L 24.773438 5.433594 L 24.574219 5.425781 L 24.574219 6.554688 L 14.511719 6.554688 L 14.511719 5.425781 L 4.25 5.425781 L 4.25 6.554688 L 1.996094 6.554688 L 1.996094 16.046875 L 7.472656 14.765625 L 7.472656 12.96875 L 14.054688 12.96875 Z M 40.28125 6.785156 L 51.796875 6.785156 L 51.796875 7.582031 L 40.28125 7.582031 Z M 29.789062 3.507812 L 35.433594 3.507812 L 35.433594 5.425781 L 29.789062 5.425781 Z M 29.789062 3.507812 "
					fill-opacity="1"
					fill-rule="nonzero"
				/>
			</g>
		</svg>
	);
};
