import React from 'react';
import styled from 'styled-components';
import { NameAnimation } from '../NameAnimation';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusBlack.png');

export const TeamMobile = () => {
	return (
		<Wrapper>
			<h1>
				Team <br /> <span>Work.</span>
			</h1>
			<section>
				<NameAnimation name="GOSHAWTY" />
				<NameAnimation name="SLEG.FX" isReverse />
				<NameAnimation name="VLADIKK317" />
				<NameAnimation name="NICOLAS CAGE" isReverse />
				<NameAnimation name="YANKIE" />
				<NameAnimation name="BU1BAZAWR" isReverse />
				<NameAnimation name="DXDY0X" />
			</section>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	border-radius: 10px;
	height: 550px;
	overflow: hidden;

	h1 {
		padding-left: 30px;
		padding-top: 40px;
		font-family: 'SF-Bold';
		text-align: left;
		line-height: 0.9;
		font-size: 80px;
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#90ca5a),
			color-stop(50%, #d7d65a),
			color-stop(70%, #dac355)
		);
		background: -webkit-linear-gradient(
			left,
			#90ca5a,
			#d7d65a 50%,
			#dac355 70%
		);
		background: linear-gradient(90deg, #90ca5a, #d7d65a 50%, #dac355 70%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: ${fadeInUp} 5s forwards;

		span {
			font-size: 100px;
		}
	}

	section {
		margin-top: 110px;
		transform: rotate(-5deg);
		width: 110%;
		margin-left: -20px;

		@media screen and (max-width: 1170px) {
			margin-top: 50px;
		}
	}

	> div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			cursor: pointer;
			width: 100%;

			&:hover {
				opacity: 0.5;
			}
		}
	}
`;
