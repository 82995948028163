import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusBlack.png');

type LiveFaceProps = {
	setOpen: (value: boolean) => void;
};

export const LiveFace: FC<LiveFaceProps> = ({ setOpen }) => {
	return (
		<Wrapper>
			<h1>
				FX, Gold <br /> and Indices <br />
				Live-Trading <br />
				with GoShawty
				<br /> and the Team.
			</h1>
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;

	&:hover > div img {
		opacity: 0.25;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			opacity: 0.4;
			cursor: pointer;
			width: 100%;
		}
	}

	// custom here
	display: flex;
	align-items: center;
	padding: 95px 20px 95px 95px;

	h1 {
		line-height: 1.1;
		text-align: left;
		font-size: 65px;
		background: #939393;
		background: linear-gradient(to bottom right, #939393 0%, #c8c8c8 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: ${fadeInUp} 4s forwards;
	}

	@media screen and (max-width: 1170px) {
		padding-left: 60px;

		h1 {
			font-size: 60px;
		}
	}
`;
