/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import 'animate.css/animate.compat.css';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';

type BigCardProps = {
	face: ReactNode;
	back: ReactNode;
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	onClick?: () => void;
	isTop?: boolean;
};

export const BigCard: FC<BigCardProps> = ({
	face,
	back,
	isOpen,
	setOpen,
	onClick,
	isTop,
}) => {
	const cardRef = useRef();
	const innerRef = useOutsideClick(() => {
		setOpen(false);
	});
	const { inViewport } = useInViewport(
		cardRef as unknown as RefObject<HTMLElement>
	);

	useEffect(() => {
		!inViewport && setOpen(false);
	}, [inViewport]);

	return (
		<>
			{isOpen && <Backdrop />}
			<StyledScrollAnimation
				animateIn="animateFadeIn"
				animatePreScroll={false}
				animateOnce
				duration={1.5}
				offset={0}
				open={isOpen}
			>
				{/* @ts-ignore */}
				<FlipCard open={isOpen} ref={cardRef} onClick={onClick}>
					<FlipCardInner open={isOpen}>
						<FlipCardFront isTop={isTop} onClick={() => setOpen(true)}>
							<div>{face}</div>
						</FlipCardFront>
						<FlipCardBack ref={innerRef}>
							<div>{back}</div>
						</FlipCardBack>
					</FlipCardInner>
				</FlipCard>
			</StyledScrollAnimation>
		</>
	);
};

const StyledScrollAnimation = styled(ScrollAnimation)<{ open: boolean }>`
	z-index: ${({ open }) => (open ? 1000001 : 3)};
	position: relative;
`;

const Backdrop = styled.section`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;
`;

const FlipCard = styled.div<{ open: boolean }>`
	position: relative;
	z-index: ${({ open }) => (open ? 1000001 : 3)};
	height: 820px;
	width: 1140px;
	perspective: 3000px;
	margin-top: 10px;

	> div > div {
		transition: ${({ open }) => (open ? '0.4s' : '1s')};
		transition-delay: ${({ open }) => (open ? '0s' : '0.5s')};
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;
	}
`;

const FlipCardInner = styled.div<{ open: boolean }>`
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 1.2s;
	transform-style: preserve-3d;
	transform: ${({ open }) => open && 'rotateX(180deg)'};
`;

const FlipCardSide = styled.div<{ isTop?: boolean }>`
	position: absolute;
	display: flex;
	user-select: none;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 40px;
	background: #fff;
`;

const FlipCardFront = styled(FlipCardSide)`
	justify-content: center;
	overflow: hidden;

	&:hover {
		cursor: pointer;
	}
`;

const FlipCardBack = styled(FlipCardSide)`
	transform: rotateY(180deg);
	justify-content: flex-end;

	> div {
		transform: rotateX(180deg) rotateY(180deg);
	}
`;
