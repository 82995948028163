import React from 'react';
import styled from 'styled-components';
import { relocate } from '../Header';
const logo = require('../../assets/BlackLogo.png');
const arrow = require('../../assets/arrowBlack.png');

export const Footer = () => {
	return (
		<Wrap>
			<Top>
				<div>
					<img src={logo} alt="Logo" />
				</div>
				<div id="arrow">
					<img src={arrow} alt="arrow" />
				</div>
				<p>GoShawty Club</p>
				<div id="arrow">
					<img src={arrow} alt="arrow" />
				</div>
				<p>Best or nothing.</p>
			</Top>
			<Bottom>
				<div>
					<h6>GoShawty</h6>
					<p onClick={() => relocate('https://t.me/+40hgtZ2jmvNhMmEy')}>
						Telegram
					</p>
					<p onClick={() => relocate('https://instagram.com/goshawtyxx')}>
						Instagram
					</p>
					<p onClick={() => relocate('https://www.youtube.com/@GoShawtyFx')}>
						YouTube
					</p>
					<p onClick={() => relocate('https://goshawty.net/indicators')}>
						Indicators
					</p>
					<p onClick={() => relocate('https://goshawty.net/mentorship')}>
						Mentorship
					</p>
					<p onClick={() => relocate('https://goshawty.net/results')}>
						Club Reviews
					</p>

					<h6>Yankie</h6>
					<p
						onClick={() =>
							relocate('https://www.instagram.com/stanislavenkoyevgen/')
						}
					>
						Instagram
					</p>
				</div>
				<div>
					<h6>DXDY0X</h6>
					<p onClick={() => relocate('https://www.instagram.com/dxdy0x/')}>
						Instagram
					</p>

					<h6>Vladikk317</h6>
					<p onClick={() => relocate('https://t.me/vladikk317trades')}>
						Telegram
					</p>
					<p onClick={() => relocate('https://instagram.com/vladikk317')}>
						Instagram
					</p>

					<h6>Sleg.fx</h6>
					<p onClick={() => relocate('https://www.instagram.com/whoissleg/')}>
						Instagram
					</p>
					<p onClick={() => relocate('https://t.me/sleg_fx')}>Telegram</p>
				</div>
				<div>
					<h6>bu1bazawr</h6>
					<p onClick={() => relocate('https://www.instagram.com/bu1bazawr/')}>
						Instagram
					</p>
					<p onClick={() => relocate('https://t.me/bu1bazawrfx')}>Telegram</p>

					<h6>Cage</h6>
					<p onClick={() => relocate('https://instagram.com/buganov.d')}>
						Instagram
					</p>
				</div>
			</Bottom>
			<article>
				<p>
					Trade responsible. Do not use shitty prop firms. Use{' '}
					<span onClick={() => relocate('https://ftmo.com/en/')}>FTMO</span>.
				</p>
				<p>Designed and developed by GoShawty © 2024</p>
			</article>
		</Wrap>
	);
};

const Bottom = styled.section`
	display: flex;
	align-items: flex-start;
	width: 1000px;
	padding-bottom: 40px;

	@media screen and (max-width: 1060px) {
		width: 100%;
	}

	div {
		min-width: 100px;
		margin-left: 30px;

		&:nth-of-type(1) {
			margin-left: 0px;
		}
	}

	h6 {
		margin-top: 24px;
		user-select: none;
		cursor: default;
		font-size: 12px;
		opacity: 0.75;
		font-family: 'SF-Medium';
	}

	p {
		margin-top: 10px;
		user-select: none;
		cursor: pointer;
		font-size: 12px;
		opacity: 0.6;
		font-family: 'SF-Regular';

		&:hover {
			text-decoration: underline;
		}
	}
`;

const Wrap = styled.div`
	background: rgb(245, 245, 247);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px 16px 20px;
	overflow: hidden;

	> article {
		width: 1000px;

		p {
			text-align: left;
			font-size: 12px;
			font-family: 'SF-Regular';
			color: #6e6e6e;
			user-select: none;

			span {
				opacity: 1;
				color: rgb(15, 115, 214);

				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}

			&:nth-of-type(1) {
				border-bottom: 1px solid rgba(0, 0, 0, 0.16);
				margin-bottom: 15px;
				padding-bottom: 15px;
			}

			&:nth-of-type(2) {
				@media screen and (max-width: 734px) {
					padding-bottom: 20px;
				}
			}
		}

		@media screen and (max-width: 1060px) {
			width: 100%;
		}
	}
`;

const Top = styled.section`
	padding: 27px 0;
	width: 1000px;
	display: flex;
	align-items: center;
	height: 22px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);

	@media screen and (max-width: 1060px) {
		width: 100%;
		padding-top: 27px;
		padding-bottom: 27px;
	}

	p {
		font-size: 12px;
		font-family: 'SF-Regular';
		opacity: 0.6;
		user-select: none;
	}

	div {
		height: 18px;
		opacity: 0.8;

		&#arrow {
			transform: scale(0.5);
			margin: 10px;
			opacity: 0.5;
		}

		img {
			height: 100%;
		}
	}
`;
