import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const arrow = require('../../assets/arrow.png');
const brain = require('../../assets/brain.png');

export const JoinFace = () => {
	return (
		<Wrap>
			<section>
				<div>
					<img src={brain} alt="brain" id="brain" />
				</div>
			</section>
			<h1>
				Join the <br /> GoShawty Club.
			</h1>
			<p>Aquire best or nothing.</p>
			<div>
				<img src={arrow} alt="arrow" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 820px;
	width: 1140px;
	padding-right: 150px;
	padding-left: 95px;
	background: #000;

	&:hover > div img {
		opacity: 0.5;
	}

	h1 {
		color: white;
		font-size: 130px;
		animation: ${fadeInUp} 3s forwards;
	}

	> div {
		width: 40px;
		position: absolute;
		bottom: 40px;
		right: 40px;

		img {
			width: 100%;

			&#brain {
				animation: ${fadeInUp} 5s forwards;
			}
		}
	}

	p {
		margin-top: 20px;
		font-family: 'SF-Bold';
		font-size: 40px;
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#61a87b),
			color-stop(25%, #69bf74),
			color-stop(84%, #b8ce66)
		);
		background: -webkit-linear-gradient(
			left,
			#61a87b,
			#69bf74 25%,
			#b8ce66 84%
		);
		background: linear-gradient(90deg, #61a87b, #69bf74 25%, #b8ce66 84%);
		display: inline-block;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	> section {
		display: flex;
		justify-content: flex-end;
		width: 100%;

		div {
			width: 250px;

			img {
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;
		padding-left: 60px;
		padding-right: 100px;

		h1 {
			font-size: 90px;
		}

		p {
			font-size: 30px;
		}
	}
`;
