import { createGlobalStyle } from 'styled-components';
import '../fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF';
import '../fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF';
import '../fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF';

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body{
        background: rgb(245, 245, 247);
    }

    html {
        scroll-behavior: smooth;
    }

    img {
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    *, p{
        font-family: "SF-Medium";
    }

    a{
        font-family: "SF-Regular";
    }

    h1,h2,h3,h4,h5,h6 {
        line-height: 1.04167;
        letter-spacing: -.015em;
        font-family: "SF-Medium";
    }
`;
