import React, { FC } from 'react';

type PlusProps = {
	color?: string;
};

export const Plus: FC<PlusProps> = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			zoomAndPan="magnify"
			viewBox="0 0 21.75 21.75"
			height="29"
			preserveAspectRatio="xMidYMid meet"
			version="1.0"
		>
			<defs>
				<clipPath id="2259b4e3a2">
					<path
						d="M 0 0 L 20.761719 0 L 20.761719 20.761719 L 0 20.761719 Z M 0 0 "
						clip-rule="nonzero"
					/>
				</clipPath>
			</defs>
			<g clip-path="url(#2259b4e3a2)">
				<path
					fill={color}
					d="M 10.390625 0 C 4.660156 0 0 4.660156 0 10.390625 C 0 16.121094 4.660156 20.785156 10.390625 20.785156 C 16.121094 20.785156 20.785156 16.121094 20.785156 10.390625 C 20.785156 4.660156 16.121094 0 10.390625 0 Z M 10.390625 19.398438 C 5.425781 19.398438 1.386719 15.359375 1.386719 10.390625 C 1.386719 5.425781 5.425781 1.386719 10.390625 1.386719 C 15.359375 1.386719 19.398438 5.425781 19.398438 10.390625 C 19.398438 15.359375 15.359375 19.398438 10.390625 19.398438 Z M 10.390625 19.398438 "
					fill-opacity="1"
					fill-rule="nonzero"
				/>
			</g>
			<path
				fill={color}
				d="M 14.015625 9.699219 L 11.085938 9.699219 L 11.085938 6.765625 C 11.085938 6.386719 10.773438 6.074219 10.390625 6.074219 C 10.007812 6.074219 9.699219 6.386719 9.699219 6.765625 L 9.699219 9.699219 L 6.765625 9.699219 C 6.386719 9.699219 6.074219 10.007812 6.074219 10.390625 C 6.074219 10.773438 6.386719 11.085938 6.765625 11.085938 L 9.699219 11.085938 L 9.699219 14.015625 C 9.699219 14.398438 10.007812 14.710938 10.390625 14.710938 C 10.773438 14.710938 11.085938 14.398438 11.085938 14.015625 L 11.085938 11.085938 L 14.015625 11.085938 C 14.398438 11.085938 14.710938 10.773438 14.710938 10.390625 C 14.710938 10.007812 14.398438 9.699219 14.015625 9.699219 Z M 14.015625 9.699219 "
				fill-opacity="1"
				fill-rule="nonzero"
			/>
		</svg>
	);
};
