import React, { FC, RefObject, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Bars } from '../Bars';
import { useInViewport } from 'react-in-viewport';
import { useScreenSize } from '../../hooks/useOutsideClick';
import { TABLET_WIDTH } from '../../constants/constants';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');

type MorningFaceProps = {
	setOpen: (value: boolean) => void;
};

export const MorningFace: FC<MorningFaceProps> = ({ setOpen }) => {
	const ref = useRef();
	const { inViewport } = useInViewport(
		ref as unknown as RefObject<HTMLElement>
	);
	const screenSize = useScreenSize();
	const isTabletWidth = useMemo(
		() => screenSize.width < TABLET_WIDTH,
		[screenSize]
	);

	return (
		// @ts-ignore
		<Wrapper ref={ref}>
			<h1>Morning calls and week overviews.</h1>
			<Bars inViewport={inViewport} isMedium={isTabletWidth} />
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;
	background: #000;

	&:hover > div img {
		opacity: 0.5;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	> div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			cursor: pointer;
			width: 100%;
		}
	}

	// custom here
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 95px;

	h1 {
		color: white;
		line-height: 1.1;
		text-align: left;
		font-size: 66px;
		animation: ${fadeInUp} 4s forwards;
	}

	@media screen and (max-width: 1170px) {
		padding: 60px;

		h1 {
			font-size: 60px;
		}
	}
`;
