import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const iphone = require('../../assets/iphone.png');
const plus = require('../../assets/PlusBlack.png');

export const CreatedByMobile = () => {
	return (
		<Wrap>
			<h1>
				Created by <br /> <span>GoShawty.</span>
			</h1>
			<h2>Powered by People.</h2>
			<section>
				<img src={iphone} alt="Phone" />
			</section>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		margin-top: 40px;
		font-size: 55px;
		animation: ${fadeInUp} 2s forwards;
	}

	h2 {
		margin-top: 30px;
		animation: ${fadeInUp} 2s forwards;
	}

	section {
		margin-top: 60px;
		width: 230px;
		height: 200px;
		overflow: hidden;

		img {
			width: 100%;
			animation: ${fadeInUp} 6s forwards;
		}
	}

	div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			width: 100%;
		}
	}
`;
