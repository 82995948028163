import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	animateBar1,
	animateBar2,
	animateBar3,
	animateBar4,
	animateBar5,
} from '../../styles/animations';
import { useMountTransition } from '../../hooks/useOutsideClick';

type BarsProps = {
	isSmall?: boolean;
	isMedium?: boolean;
	inViewport: boolean;
};

export const Bars: FC<BarsProps> = ({ isSmall, isMedium, inViewport }) => {
	const [isMounted, setIsMounted] = useState(false);

	const hasTransitionedIn = useMountTransition(isMounted, 1000);

	useEffect(() => {
		inViewport && setIsMounted(true);
	}, [inViewport]);

	return (
		<>
			{hasTransitionedIn && (
				<BarWrapper isSmall={isSmall} isMedium={isMedium}>
					<Bar />
					<Bar />
					<Bar />
					<Bar />
					<Bar />
				</BarWrapper>
			)}
		</>
	);
};

const BarWrapper = styled.section<{ isSmall?: boolean; isMedium?: boolean }>`
	height: ${({ isSmall, isMedium }) =>
		isSmall ? '200px' : isMedium ? '140px' : '230px'};
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: ${({ isSmall }) => (isSmall ? '290px' : '100%')};
	transition: 0.3s;
	margin-bottom: ${({ isSmall, isMedium }) =>
		isSmall ? '80px' : isMedium && '35px'};
`;

const Bar = styled.div`
	width: 30px;
	height: 100%;
	border-radius: 5px;
	background: #fff;

	&:nth-of-type(1) {
		-webkit-animation: ${animateBar1} 4s 1s forwards;
		animation: ${animateBar1} 4s 1s forwards;
	}
	&:nth-of-type(2) {
		-webkit-animation: ${animateBar2} 4s 1s forwards;
		animation: ${animateBar2} 4s 1s forwards;
	}
	&:nth-of-type(3) {
		-webkit-animation: ${animateBar3} 4s 1s forwards;
		animation: ${animateBar3} 4s 1s forwards;
	}
	&:nth-of-type(4) {
		-webkit-animation: ${animateBar4} 4s 1s forwards;
		animation: ${animateBar5} 4s 1s forwards;
	}
	&:nth-of-type(5) {
		-webkit-animation: ${animateBar4} 4s 1s forwards;
		animation: ${animateBar5} 4s 1s forwards;
	}

	&:nth-of-type(1) {
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#3996fa),
			color-stop(#f4b82b),
			to(#f59548)
		);
		background-image: -webkit-linear-gradient(top, #3996fa, #f4b82b, #f59548);
		background-image: linear-gradient(180deg, #3996fa, #f4b82b, #f59548);
		background-size: 100% 100%;
	}

	&:nth-of-type(2) {
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#dc7ecb),
			color-stop(#ed85bf),
			color-stop(#f38979),
			color-stop(#f5a53b),
			to(#e7b239)
		);
		background-image: -webkit-linear-gradient(
			top,
			#dc7ecb,
			#ed85bf,
			#f38979,
			#f5a53b,
			#e7b239
		);
		background-image: linear-gradient(
			180deg,
			#dc7ecb,
			#ed85bf,
			#f38979,
			#f5a53b,
			#e7b239
		);
		background-size: 100% 100%;
	}

	&:nth-of-type(3) {
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#a177ff),
			color-stop(#7d83ff),
			to(#3197ff)
		);
		background-image: -webkit-linear-gradient(top, #a177ff, #7d83ff, #3197ff);
		background-image: linear-gradient(180deg, #a177ff, #7d83ff, #3197ff);
		background-size: 100% 100%;
	}

	&:nth-of-type(4) {
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#dc7ecb),
			color-stop(#ed85bf),
			color-stop(#f38979),
			color-stop(#f5a53b),
			to(#e7b239)
		);
		background-image: -webkit-linear-gradient(
			top,
			#dc7ecb,
			#ed85bf,
			#f38979,
			#f5a53b,
			#e7b239
		);
		background-image: linear-gradient(
			180deg,
			#dc7ecb,
			#ed85bf,
			#f38979,
			#f5a53b,
			#e7b239
		);
		background-size: 100% 100%;
	}

	&:nth-of-type(5) {
		background-image: -webkit-gradient(
			linear,
			left top,
			left bottom,
			from(#4fba4e),
			color-stop(#97c030),
			to(#f5b926)
		);
		background-image: -webkit-linear-gradient(top, #4fba4e, #97c030, #f5b926);
		background-image: linear-gradient(180deg, #4fba4e, #97c030, #f5b926);
		background-size: 100% 100%;
	}
`;
