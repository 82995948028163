import React, { FC, ReactNode } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';
import styled from 'styled-components';

type MobileCardProps = {
	content: ReactNode;
	isTop?: boolean;
	onClick?: () => void;
};

export const MobileCard: FC<MobileCardProps> = ({ content, onClick }) => {
	return (
		<>
			<ScrollAnimation
				animateIn="animateFadeIn"
				animatePreScroll={false}
				animateOnce
				duration={1.5}
				offset={0}
			>
				<Card onClick={() => onClick?.()}>{content}</Card>
			</ScrollAnimation>
		</>
	);
};

const Card = styled.div`
	position: relative;
	z-index: 100000000;
	width: 350px;
	margin-top: 10px;
	min-height: 400px;
	border-radius: 10px;
	background: #fff;
	cursor: pointer;

	&:before {
		content: '';
		border-radius: 10px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}
`;
