import React, { FC } from 'react';
import styled from 'styled-components';
import { running, runningReverse } from '../../styles/animations';

type NameAnimationProps = {
	name: string;
	isReverse?: boolean;
};

export const NameAnimation: FC<NameAnimationProps> = ({
	name,
	isReverse = false,
}) => {
	const names = new Array(isReverse ? 20 : 10).fill(name).join(' ');

	return (
		<Wrap>
			<Marquee isReverse={isReverse}>
				<p>{names}</p>
				<p>{names}</p>
			</Marquee>
		</Wrap>
	);
};

const Wrap = styled.div`
	max-width: 100%;
	overflow: hidden;
`;

const Marquee = styled.div<{ isReverse?: boolean }>`
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	animation: ${({ isReverse }) => (isReverse ? runningReverse : running)}
		${({ isReverse }) => (isReverse ? '50s' : '25s')} linear infinite;

	p {
		font-size: 20px;
		display: inline-block;
		color: gray;

		&:nth-of-type(2) {
			margin-left: 5px;
		}
	}
`;
