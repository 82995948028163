import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const TPO = require('../../assets/TPO.png');
const plus = require('../../assets/PlusWhite.png');

export const StreamsFace = () => {
	return (
		<Wrap>
			<div>
				<h1>
					Get weekly <br />
					<span>Thematic</span>
					<br />
					streams.
				</h1>
				<p>Gain knowledge and grow as a trader.</p>
				<div>
					<img src={plus} alt="plus" />
				</div>
			</div>
			<section>
				<img src={TPO} alt="TPO" />
			</section>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 820px;
	width: 1140px;
	padding-right: 10px;
	padding-left: 95px;
	background: #000;

	&:hover > div img {
		opacity: 0.5;
	}

	h1 {
		color: white;
		font-size: 130px;
		line-height: 0.95;
		animation: ${fadeInUp} 4s forwards;

		span {
			background: -webkit-gradient(
				linear,
				right top,
				left top,
				from(#61a87b),
				color-stop(25%, #69bf74),
				color-stop(84%, #b8ce66)
			);
			background: -webkit-linear-gradient(
				right,
				#61a87b,
				#69bf74 25%,
				#b8ce66 84%
			);
			background: linear-gradient(-90deg, #61a87b, #69bf74 25%, #b8ce66 84%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	> div > div {
		width: 40px;
		position: absolute;
		bottom: 40px;
		right: 40px;

		img {
			width: 100%;
		}
	}

	p {
		margin-top: 40px;
		font-family: 'SF-Bold';
		font-size: 26px;
		color: white;
	}

	> section {
		width: 400px;

		img {
			width: 100%;
		}
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;
		padding-left: 60px;
		padding-right: 20px;

		h1 {
			font-size: 90px;
		}

		p {
			font-size: 20px;
		}

		> section {
			width: 300px;
		}
	}
`;
