import { useEffect, useRef, useState } from 'react';

export const useOutsideClick = (callback: () => void) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		document.addEventListener('mouseup', handleClickOutside);
		document.addEventListener('touchend', handleClickOutside);

		return () => {
			document.removeEventListener('mouseup', handleClickOutside);
			document.removeEventListener('touchend', handleClickOutside);
		};
	}, [callback]);

	return ref;
};

export const useScreenSize = () => {
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};

export const useMountTransition = (
	isMounted: boolean,
	unmountDelay: number
) => {
	const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

	useEffect(() => {
		let timeoutId: string | number | NodeJS.Timeout | undefined;

		if (isMounted && !hasTransitionedIn) {
			setHasTransitionedIn(true);
		} else if (!isMounted && hasTransitionedIn) {
			timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [unmountDelay, isMounted, hasTransitionedIn]);

	return hasTransitionedIn;
};
