import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');
const logo = require('../../assets/WhiteLogo.png');

export const TechnicalMobile = () => {
	return (
		<Wrap>
			<section>
				<img src={logo} alt="logo" />
			</section>
			<article>
				<h1>
					Technical.
					<br />
					Knowledge.
					<br />
					Base.
				</h1>
			</article>
			<Button>
				<img src={plus} alt="plus" />
			</Button>
		</Wrap>
	);
};

const Wrap = styled.div`
	color: white;
	border-radius: 10px;
	padding-left: 30px;
	padding-top: 60px;
	height: 490px;
	display: flex;
	flex-direction: column;

	h1 {
		margin-top: 35px;
		font-size: 54px;
		line-height: 1.1;
		animation: ${fadeInUp} 3s forwards;
	}

	p {
		margin-top: 10px;
		font-family: 'SF-Bold';
		font-size: 20px;
	}

	section {
		margin-top: 25px;
		width: 100px;

		img {
			width: 100%;
			animation: ${fadeInUp} 6s forwards;
		}
	}

	background: -webkit-radial-gradient(
			5% 5%,
			ellipse,
			#f0acad 0%,
			rgba(240, 172, 173, 0) 75%
		),
		-webkit-radial-gradient(95% 5%, ellipse, #f8ae4b 0%, rgba(248, 174, 75, 0)
					75%),
		-webkit-radial-gradient(95% 95%, ellipse, #dbb048 -15%, rgba(
						219,
						176,
						72,
						0
					)
					75%),
		-webkit-radial-gradient(5% 95%, ellipse, #c284e9 50%, rgba(194, 132, 233, 0)
					75%);
	background: radial-gradient(
			ellipse at 5% 5%,
			#f0acad 0%,
			rgba(240, 172, 173, 0) 75%
		),
		radial-gradient(ellipse at 95% 5%, #f8ae4b 0%, rgba(248, 174, 75, 0) 75%),
		radial-gradient(ellipse at 95% 95%, #dbb048 -15%, rgba(219, 176, 72, 0) 75%),
		radial-gradient(ellipse at 5% 95%, #c284e9 50%, rgba(194, 132, 233, 0) 75%);
	-webkit-filter: saturate(1.3);
	filter: saturate(1.3);
`;

const Button = styled.div`
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 27px;

	img {
		cursor: pointer;
		width: 100%;

		&:hover {
			opacity: 0.5;
		}
	}
`;
