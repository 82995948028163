import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const iphone = require('../../assets/iphone.png');
const plus = require('../../assets/PlusBlack.png');

export const CreatedByFace = () => {
	return (
		<FaceBlock>
			<ImgWrap>
				<div>
					<img src={iphone} alt="Phone" />
				</div>
			</ImgWrap>
			<section>
				<div>
					<h1>
						Created by <br /> <span>GoShawty.</span>
					</h1>
					<h2>Powered by People.</h2>
				</div>
				<div>
					<img src={plus} alt="plus" />
				</div>
			</section>
		</FaceBlock>
	);
};

const ImgWrap = styled.div`
	height: 100%;
	margin-right: 45px;

	div {
		width: 400px;

		img {
			animation: ${fadeInUp} 4s forwards;
			width: 100%;
		}
	}

	@media screen and (max-width: 1170px) {
		margin-right: 25px;

		div {
			width: 300px;
		}
	}
`;

const FaceBlock = styled.div`
	display: flex;
	padding-top: 170px;

	section {
		margin-left: 45px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
		height: 100%;

		> div:nth-of-type(1) {
			animation: ${fadeInUp} 2s forwards;
		}

		> div:nth-of-type(2) {
			width: 40px;

			img {
				width: 100%;
				margin-top: 310px;
				margin-left: 50px;
			}
		}
	}

	&:hover section > div:nth-of-type(2) img {
		opacity: 0.5;
	}

	h1,
	h2 {
		font-size: 56px;
		line-height: 1.07143;
		font-family: 'SF-Bold';
		letter-spacing: -0.005em;

		span {
			font-size: 100px;
		}
	}
	h2 {
		margin-top: 50px;
		font-size: 45px;
	}

	@media screen and (max-width: 1170px) {
		section {
			margin-left: 25px;

			h1,
			h2 {
				font-size: 34px;
				span {
					font-size: 74px;
				}
			}

			> div:nth-of-type(2) img {
				margin-top: 160px;
				margin-left: 28px;
			}
		}
	}
`;
