import React from 'react';
import styled from 'styled-components';

export const TeamModalContent = () => {
	return (
		<Wrap>
			<h1>Обмін ідеями та досвідом</h1>
			<p>
				Працюйте пліч-о-пліч з іншими трейдерами та менторами в середовищі, де
				усі націлені на успіх.
			</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
