import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusBlack.png');

type PropFaceProps = {
	setOpen: (value: boolean) => void;
};

export const PropFace: FC<PropFaceProps> = ({ setOpen }) => {
	return (
		<Wrapper>
			<h1>
				Prop accounts for the most <span>active</span> Club Members.
			</h1>
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;

	&:hover > div img {
		opacity: 0.5;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			cursor: pointer;
			width: 100%;
		}
	}

	// custom here
	display: flex;
	align-items: center;
	padding: 95px;

	h1 {
		margin-top: -40px;
		line-height: 1.1;
		text-align: left;
		font-size: 80px;
		animation: ${fadeInUp} 3s forwards;

		span {
			background: #fec833;
			background: linear-gradient(to right, #fec833 0%, #e4699a 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	@media screen and (max-width: 1170px) {
		padding-left: 60px;

		h1 {
			font-size: 60px;
		}
	}
`;
