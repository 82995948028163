import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');

export const IndividualMobile = () => {
	return (
		<Wrapper>
			<section>
				<h1>Individual</h1>
				<h1>Calls.</h1>
				<h1>With</h1>
				<h1>Mentors.</h1>
			</section>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};
const Wrapper = styled.div`
	color: white;
	border-radius: 10px;
	padding-left: 30px;
	padding-top: 80px;
	height: 440px;

	h1 {
		line-height: 0.9;

		&:nth-of-type(1) {
			font-size: 59px;
			animation: ${fadeInUp} 2s forwards;
		}
		&:nth-of-type(2) {
			font-size: 96px;
			animation: ${fadeInUp} 2.5s forwards;
		}
		&:nth-of-type(3) {
			font-size: 65px;
			animation: ${fadeInUp} 3s forwards;
		}
		&:nth-of-type(4) {
			font-size: 70px;
			animation: ${fadeInUp} 3.5s forwards;
		}
	}

	div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			cursor: pointer;
			width: 100%;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	background: radial-gradient(
			ellipse at 5% 5%,
			#fec833 0%,
			rgba(254, 200, 51, 0) 75%
		),
		radial-gradient(ellipse at 95% 5%, #fdc1a7 0%, rgba(253, 193, 167, 0) 75%),
		radial-gradient(ellipse at 95% 95%, #e4699a 15%, rgba(228, 105, 154, 0) 75%),
		radial-gradient(ellipse at 5% 95%, #eb5551 30%, rgba(235, 85, 81, 0) 75%);
`;
