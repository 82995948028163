import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const TPO = require('../../assets/TPO.png');
const plus = require('../../assets/PlusWhite.png');

export const StreamsMobile = () => {
	return (
		<Wrapper>
			<h1>
				Get weekly <br />
				<span>Thematic</span>
				<br />
				streams.
			</h1>
			<p>Gain knowledge and grow as a trader.</p>
			<section>
				<img src={TPO} alt="TPO" />
			</section>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background: #000;
	border-radius: 10px;
	padding-left: 30px;
	padding-top: 40px;
	height: 560px;

	> section {
		width: 150px;

		img {
			width: 100%;
		}
	}

	p {
		color: white;
	}

	div {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 27px;

		img {
			cursor: pointer;
			width: 100%;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	h1 {
		font-size: 55px;
		color: white;
		animation: ${fadeInUp} 4s forwards;

		span {
			background: -webkit-gradient(
				linear,
				right top,
				left top,
				from(#61a87b),
				color-stop(25%, #69bf74),
				color-stop(84%, #b8ce66)
			);
			background: -webkit-linear-gradient(
				right,
				#61a87b,
				#69bf74 25%,
				#b8ce66 84%
			);
			background: linear-gradient(-90deg, #61a87b, #69bf74 25%, #b8ce66 84%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
`;
