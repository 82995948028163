/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useInViewport } from 'react-in-viewport';

type SmallCardProps = {
	face: ReactNode;
	back: ReactNode;
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	animationDelay?: boolean;
};

export const SmallCard: FC<SmallCardProps> = ({
	face,
	back,
	isOpen,
	setOpen,
	animationDelay,
}) => {
	const cardRef = useRef();
	const innerRef = useOutsideClick(() => {
		setOpen(false);
	});
	const { inViewport } = useInViewport(
		cardRef as unknown as RefObject<HTMLElement>
	);

	useEffect(() => {
		!inViewport && setOpen(false);
	}, [inViewport]);

	return (
		<>
			{isOpen && <Backdrop />}
			<StyledScrollAnimation
				delay={animationDelay ? 500 : 0}
				animateIn="animateFadeIn"
				animatePreScroll={false}
				animateOnce
				duration={animationDelay ? 1 : 1.5}
				offset={0}
				open={isOpen}
			>
				{/* @ts-ignore */}
				<FlipCard open={isOpen} ref={cardRef}>
					<FlipCardInner open={isOpen}>
						<FlipCardFront onClick={() => setOpen(true)}>
							<div>{face}</div>
						</FlipCardFront>
						<FlipCardBack ref={innerRef}>
							<div>{back}</div>
						</FlipCardBack>
					</FlipCardInner>
				</FlipCard>
			</StyledScrollAnimation>
		</>
	);
};

const StyledScrollAnimation = styled(ScrollAnimation)<{ open: boolean }>`
	z-index: ${({ open }) => (open ? 1000001 : 3)};
	position: relative;
`;

const Backdrop = styled.section`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;
`;

const FlipCard = styled.div<{ open: boolean }>`
	position: relative;
	z-index: ${({ open }) => (open ? 1000001 : 3)};
	height: 820px;
	width: 565px;
	perspective: 3000px;
	margin-top: 10px;

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}
`;

const FlipCardInner = styled.div<{ open: boolean }>`
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 1.2s;
	transform-style: preserve-3d;
	transform: ${({ open }) => (open && 'rotateY(180deg)') || ''};
`;

const FlipCardSide = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	border-radius: 40px;
	user-select: none;
	background: #fff;
	overflow: hidden;
	backface-visibility: hidden;
`;

const FlipCardFront = styled(FlipCardSide)`
	overflow: hidden;
`;

const FlipCardBack = styled(FlipCardSide)`
	transform: rotateY(180deg);
`;
