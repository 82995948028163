import React from 'react';
import styled from 'styled-components';

export const StreamsModalContent = () => {
	return (
		<Wrap>
			<h1>Тематичні стріми</h1>
			<p>
				Приймайте участь у стрімах на різні теми — від створення стратегій та
				розбору інструментів до розвитку психологічної стійкості. Понад 12 годин
				на місяц для покращення вашої торгівлі!
			</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
