import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');

type IndividualFaceProps = {
	setOpen: (value: boolean) => void;
};

export const IndividualFace: FC<IndividualFaceProps> = ({ setOpen }) => {
	return (
		<Wrapper>
			<section>
				<h1>Individual</h1>
				<h1>Calls.</h1>
				<h1>With</h1>
				<h1>Mentors.</h1>
			</section>
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;

	&:hover > div img {
		opacity: 0.5;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	section {
		color: white;
		text-align: left;
		padding-left: 95px;
		padding-top: 140px;

		h1 {
			line-height: 0.9;

			&:nth-of-type(1) {
				font-size: 98px;
				animation: ${fadeInUp} 2s forwards;
			}
			&:nth-of-type(2) {
				font-size: 152px;
				animation: ${fadeInUp} 2.5s forwards;
			}
			&:nth-of-type(3) {
				font-size: 109px;
				animation: ${fadeInUp} 3s forwards;
			}
			&:nth-of-type(4) {
				font-size: 107px;
				animation: ${fadeInUp} 3.5s forwards;
			}
		}

		@media screen and (max-width: 1170px) {
			padding-left: 60px;
			padding-top: 80px;

			h1 {
				&:nth-of-type(1) {
					font-size: 73px;
				}
				&:nth-of-type(2) {
					font-size: 110px;
				}
				&:nth-of-type(3) {
					font-size: 82px;
				}
				&:nth-of-type(4) {
					font-size: 79px;
				}
			}
		}
	}

	div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			cursor: pointer;
			width: 100%;
		}
	}

	background: radial-gradient(
			ellipse at 5% 5%,
			#fec833 0%,
			rgba(254, 200, 51, 0) 75%
		),
		radial-gradient(ellipse at 95% 5%, #fdc1a7 0%, rgba(253, 193, 167, 0) 75%),
		radial-gradient(ellipse at 95% 95%, #e4699a 15%, rgba(228, 105, 154, 0) 75%),
		radial-gradient(ellipse at 5% 95%, #eb5551 30%, rgba(235, 85, 81, 0) 75%);
`;
