import React, { useEffect } from 'react';
import { Heading } from './Heading';
import styled from 'styled-components';
import { List } from './List';
import { Connect } from './Connect';

export const Research = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper>
			<Heading />
			<List />
			<Connect />
		</Wrapper>
	);
};

const Wrapper = styled.div``;
