import { FC, ReactNode } from 'react';
import styled from 'styled-components';
const plus = require('../../assets/PlusBlack.png');

type MobileModalProps = {
	isOpen: boolean;
	close: () => void;
	content: ReactNode;
};

export const MobileModal: FC<MobileModalProps> = ({
	isOpen,
	close,
	content,
}) => {
	return (
		<Modal isOpen={isOpen}>
			<Close>
				<img src={plus} alt="plus" onClick={() => close()} />
			</Close>
			<div>{content}</div>
		</Modal>
	);
};

const Modal = styled.div<{ isOpen: boolean }>`
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: white;
	z-index: ${({ isOpen }) => (isOpen ? '10000000000' : '-1')};
	opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
	transition: 0.2s;
	padding: 120px 40px;
	overflow: scroll;
`;

const Close = styled.div`
	width: 27px;
	position: absolute;
	margin: -55px 0px 0px -20px;

	img {
		cursor: pointer;
		width: 100%;
		transform: rotate(45deg);

		&:hover {
			opacity: 0.5;
		}
	}
`;
