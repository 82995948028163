import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');
const logo = require('../../assets/WhiteLogo.png');

export const TechnicalFace = () => {
	return (
		<Wrap>
			<article>
				<h1>
					Technical.
					<br />
					Knowledge.
					<br />
					Base.
				</h1>
			</article>
			<section>
				<img src={logo} alt="logo" />
			</section>
			<div>
				<img src={plus} alt="plus" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 820px;
	width: 1140px;
	padding-right: 150px;
	padding-left: 95px;

	&:hover > div img {
		opacity: 0.5;
	}

	h1 {
		margin-top: 170px;
		color: white;
		font-size: 130px;
		line-height: 0.95;
		animation: ${fadeInUp} 3s forwards;
	}

	section {
		display: flex;
		width: 250px;
		height: 100%;

		img {
			animation: ${fadeInUp} 6s forwards;
			margin-top: 100px;
			width: 100%;
			height: 250px;
		}
	}

	> div {
		width: 40px;
		position: absolute;
		bottom: 40px;
		right: 40px;

		img {
			width: 100%;
		}
	}

	background: -webkit-radial-gradient(
			5% 5%,
			ellipse,
			#f0acad 0%,
			rgba(240, 172, 173, 0) 75%
		),
		-webkit-radial-gradient(95% 5%, ellipse, #f8ae4b 0%, rgba(248, 174, 75, 0)
					75%),
		-webkit-radial-gradient(95% 95%, ellipse, #dbb048 -15%, rgba(
						219,
						176,
						72,
						0
					)
					75%),
		-webkit-radial-gradient(5% 95%, ellipse, #c284e9 50%, rgba(194, 132, 233, 0)
					75%);
	background: radial-gradient(
			ellipse at 5% 5%,
			#f0acad 0%,
			rgba(240, 172, 173, 0) 75%
		),
		radial-gradient(ellipse at 95% 5%, #f8ae4b 0%, rgba(248, 174, 75, 0) 75%),
		radial-gradient(ellipse at 95% 95%, #dbb048 -15%, rgba(219, 176, 72, 0) 75%),
		radial-gradient(ellipse at 5% 95%, #c284e9 50%, rgba(194, 132, 233, 0) 75%);
	-webkit-filter: saturate(1.3);
	filter: saturate(1.3);

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;
		padding-left: 70px;
		padding-right: 100px;

		h1 {
			font-size: 90px;
		}

		p {
			font-size: 20px;
		}

		> section {
			width: 190px;

			img {
				margin-top: 70px;
				height: 190px;
			}
		}
	}
`;
