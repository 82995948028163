import React from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const arrow = require('../../assets/arrow.png');
const brain = require('../../assets/brain.png');

export const JoinMobile = () => {
	return (
		<Wrap>
			<section>
				<div>
					<img src={brain} id="brain" alt="brain" />
				</div>
			</section>
			<h1>
				Join the <br /> GoShawty
				<br /> Club.
			</h1>
			<p>Aquire best or nothing.</p>
			<Button>
				<img src={arrow} alt="arrow" />
			</Button>
		</Wrap>
	);
};

const Wrap = styled.div`
	background: black;
	color: white;
	border-radius: 10px;
	padding-left: 30px;
	padding-top: 60px;
	height: 490px;
	display: flex;
	flex-direction: column;

	h1 {
		margin-top: 20px;
		font-size: 54px;
		line-height: 1.1;
		animation: ${fadeInUp} 3s forwards;
	}

	p {
		margin-top: 10px;
		font-family: 'SF-Bold';
		font-size: 20px;
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#61a87b),
			color-stop(25%, #69bf74),
			color-stop(84%, #b8ce66)
		);
		background: -webkit-linear-gradient(
			left,
			#61a87b,
			#69bf74 25%,
			#b8ce66 84%
		);
		background: linear-gradient(90deg, #61a87b, #69bf74 25%, #b8ce66 84%);
		display: inline-block;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	section div {
		width: 150px;

		img {
			width: 100%;

			&#brain {
				animation: ${fadeInUp} 5s forwards;
			}
		}
	}
`;

const Button = styled.div`
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 27px;

	img {
		cursor: pointer;
		width: 100%;

		&:hover {
			opacity: 0.5;
		}
	}
`;
