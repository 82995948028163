import React, { FC } from 'react';
import styled from 'styled-components';
const plus = require('../../assets/PlusBlack.png');
const goshawtyMac = require('../../assets/goshawtyMac.webp');
const bu1bazawrMac = require('../../assets/bu1bazawrMac.webp');
const misterxMac = require('../../assets/misterxMac.webp');

type StreamsBackProps = {
	setOpen: (value: boolean) => void;
};

export const StreamsBack: FC<StreamsBackProps> = ({ setOpen }) => {
	return (
		<Wrap>
			<article>
				<Heading>Тематичні стріми</Heading>
				<p>
					Приймайте участь у стрімах на різні теми — від створення стратегій та
					розбору інструментів до розвитку психологічної стійкості. Понад 12
					годин на місяц для покращення вашої торгівлі!
				</p>
			</article>
			<section>
				<div>
					<img src={bu1bazawrMac} alt="bu1bazawrMac" />
				</div>
				<div>
					<img src={goshawtyMac} alt="goshawtyMac" />
				</div>
				<div>
					<img src={misterxMac} alt="misterxMac" />
				</div>
			</section>
			<div onClick={() => setOpen(false)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	height: 820px;
	width: 1140px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	p {
		width: 390px;
		margin-top: 22px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}

	section {
		display: flex;
		width: 500px;

		div {
			width: 33%;

			img {
				width: 100%;
			}
		}
	}

	> div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			transform: rotate(45deg);
			width: 100%;

			&:hover {
				opacity: 0.5;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;
		padding-left: 60px;

		section {
			width: 350px;
		}

		p {
			width: 300px;
			font-size: 13px;
		}
	}
`;

const Heading = styled.h2`
	font-size: 21px;
	line-height: 1.381;
	font-weight: 400;
	letter-spacing: 0.015em;
	color: #656565;
	font-family: 'SF-Bold';

	@media screen and (max-width: 1170px) {
		font-size: 13px;
	}
`;
