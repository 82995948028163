import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
const plus = require('../../assets/PlusWhite.png');

type AnalysisFaceProps = {
	setOpen: (value: boolean) => void;
};

export const AnalysisFace: FC<AnalysisFaceProps> = ({ setOpen }) => {
	return (
		<Wrapper>
			<section>
				<h1>Dive deeper</h1>
				<h1>into</h1>
				<h1>Market</h1>
				<h1>Analysis.</h1>
			</section>
			<div onClick={() => setOpen(true)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: 820px;
	width: 565px;
	cursor: pointer;

	&:hover > div img {
		opacity: 0.5;
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
	}

	div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			cursor: pointer;
			width: 100%;
		}
	}

	section {
		padding-top: 100px;
		padding-left: 95px;

		h1 {
			font-size: 70px;
			text-align: left;
			line-height: 0.9;
			animation: ${fadeInUp} 3s forwards;

			&:nth-of-type(2) {
				font-size: 130px;
				animation: ${fadeInUp} 3.5s forwards;
			}
			&:nth-of-type(3),
			&:nth-of-type(4) {
				font-size: 100px;
				animation: ${fadeInUp} 4s forwards;
			}

			@media screen and (max-width: 1170px) {
				font-size: 55px;

				&:nth-of-type(2) {
					font-size: 100px;
				}
				&:nth-of-type(3),
				&:nth-of-type(4) {
					font-size: 75px;
				}
			}
		}

		@media screen and (max-width: 1170px) {
			padding-top: 60px;
			padding-left: 60px;
		}
	}

	background: -webkit-radial-gradient(
			5% 5%,
			ellipse,
			#7bcf6a 0%,
			rgba(123, 207, 106, 0) 75%
		),
		-webkit-radial-gradient(95% 5%, ellipse, #aec154 0%, rgba(174, 193, 84, 0)
					75%),
		-webkit-radial-gradient(95% 95%, ellipse, #68a9bb 0%, rgba(104, 169, 187, 0)
					75%),
		-webkit-radial-gradient(5% 95%, ellipse, #5fc28a 0%, rgba(95, 194, 138, 0)
					75%);
	background: radial-gradient(
			ellipse at 5% 5%,
			#7bcf6a 0%,
			rgba(123, 207, 106, 0) 75%
		),
		radial-gradient(ellipse at 95% 5%, #aec154 0%, rgba(174, 193, 84, 0) 75%),
		radial-gradient(ellipse at 95% 95%, #68a9bb 0%, rgba(104, 169, 187, 0) 75%),
		radial-gradient(ellipse at 5% 95%, #5fc28a 0%, rgba(95, 194, 138, 0) 75%);
	-webkit-filter: saturate(1.2);
	filter: saturate(1.2);
	color: #fff;
	opacity: 1;
`;
