import React from 'react';
import styled from 'styled-components';

export const MorningModalContent = () => {
	return (
		<Wrap>
			<h1>Брекфаст колли та розбори тижня</h1>
			<p>
				Розпочинайте день зі швидкого ранкового стріму з аналізом ринку.
				Завершуйте тиждень нашими оглядами — тут ми розглядаємо ключові події,
				що вплинули на ринок, та аналізуємо, що працювало найкраще, розбираємо
				помилки учасників та даємо рекомендації.
			</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
